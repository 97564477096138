import { forwardRef } from 'react';
import type { SVGProps } from 'react';

import { palette } from "theme";

type Props = SVGProps<SVGSVGElement> & { disabled?: boolean; partial?: boolean; active?: boolean };

const Checkbox = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { disabled, partial, active, ...otherProps } = props;
  const showFill = active || partial || disabled;
  const activeFill = active || partial ? palette.icons.gray : palette.icons.gray2;
  const fillColor = disabled ? activeFill : palette.primary.main;
  const strokeColor = active || partial ? fillColor : palette.icons.gray;

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      ref={ref}
      {...otherProps}
    >
      <rect
        x={5.375}
        y={5.375}
        width={13.25}
        height={13.25}
        rx={1.625}
        fill={showFill ? fillColor : 'none'}
        stroke={strokeColor}
        strokeWidth={0.75}
      />
      {active && (
        <path
          d="M8 11.688l2.74 2.645L16 9"
          stroke="#fff"
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {(partial && !active) && (
        <line
          x1={8.5}
          y1={12}
          x2={15.5}
          y2={12}
          stroke="#fff"
          strokeLinecap="round"
        />
      )}
    </svg>
  );
});

Checkbox.defaultProps = {
  disabled: false,
  partial: false,
  active: false,
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
